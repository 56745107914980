import React from 'react';
import ReactDOM from 'react-dom/client';
import Footer from './components/footer';
import Header from './components/header';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Button from './components/button';
import Cognido from './pages/products/cognido';
import Privacy from './pages/legal/privacy';
import Terms from './pages/legal/terms';
import ResetPassword from './pages/auth/reset-password';
import PlayDuel from './pages/links/play-duel';
import DeleteAccount from './pages/links/delete-account';
import Press from './pages/press/press';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Cognido />} />
          <Route path="legal/">
            <Route path="privacy" element={<Privacy />} />
            <Route path="terms" element={<Terms />} />
          </Route>
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="play-duel" element={<PlayDuel />} />
          <Route path="delete-account" element={<DeleteAccount />} />
          <Route path="press" element={<Press />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
