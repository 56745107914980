import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";

import icon from "../../assets/branding/cognido-icon.png"

import appstore from "../../assets/assets/app_store_badge.png"
import googleplay from "../../assets/assets/google_play_badge.png"

export default function PlayDuel() {

    const queryParameters = new URLSearchParams(window.location.search);
    const uid = queryParameters.get("uid");

    const navigate = useNavigate();

    useEffect(() => {
        if (uid) {
            window.location.href = "cognido://play-duel?uid=" + uid
        }
        document.title = 'Invite Link - Cognido';
    }, []);

    return (
        <div>
            <Header background={"blb"} btnShown={false} sticky={true} />
            <div className="sct fxc aic">
                <div className="c pd4824 lnk fxc aic gp32">
                    <div className="fnts24 cog4t fntw700 fcb">How to play against your friends?</div>
                    <div className="fxc aic gp48">
                        <div className="fxc gp24">
                            <div className="fnts18 cog4t fntw600 fcb">If you already have the app:</div>
                            <div className="fxr gp12">
                                <div className="nmbrc fclg fnts12 fntw700">1</div>
                                <div className="fnts16 pdt1">Accept the redirect to the app. Refresh the page if you missed it.</div>
                            </div>
                            <div className="fxr gp12">
                                <div className="nmbrc fclg fnts12 fntw700">2</div>
                                <div className="fnts16 pdt1">A match is automatically created once you're in the app.</div>
                            </div>
                        </div>
                        <div className="fxc gp24">
                            <div className="fnts18 cog4t fntw600 fcb">If you don't have the app yet:</div>
                            <div className="fxr gp12">
                                <div className="nmbrc fclg fnts12 fntw700">1</div>
                                <div className="fxc gp12 pdt1">

                                    <div className="fnts16">Download the app from the App Store or Google Play.</div>
                                    <div className="fxr gp8 appbtns">
                                        <img src={appstore} onClick={() => {
                                            window.open("https://apps.apple.com/us/app/cognido-minigame-duels/id6670284138");
                                        }} />
                                        <img src={googleplay} onClick={() => {
                                            window.open("https://play.google.com/store/apps/details?id=de.davidschreiber.cognido");
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className="fxr gp12 pdt">
                                <div className="nmbrc fclg fnts12 fntw700">2</div>
                                <div className="fnts16 pdt1">Get back to this page, refresh it and accept the redirect to the app.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}