import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";

import icon from "../../assets/branding/cognido-icon.png"

import appstore from "../../assets/assets/app_store_badge.png"
import googleplay from "../../assets/assets/google_play_badge.png"

export default function DeleteAccount() {

    useEffect(() => {
        document.title = 'Delete account - Cognido';
    }, []);

    return (
        <div>
            <Header background={"blb"} btnShown={false} sticky={true} />
            <div className="sct fxc aic">
                <div className="c pd4824 lnk fxc aic gp32">
                    <div className="fnts24 cog4t fntw700 fcb">How to delete your account?</div>
                    <div className="fnts16 fntw400 fcdg">You can delete your account from inside the app on your profile page. However, if you don't got access to the app anymore and want your account to get deleted, please contact us at support@cognido.io.</div>
                </div>
            </div>
        </div>
    )
}